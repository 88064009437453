import React, { ReactNode } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/modal'
import { Text } from '@chakra-ui/layout'

interface Props {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

const WarningModal = ({ children, isOpen, onClose }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader color="white">Oops!</ModalHeader>
      <ModalBody>
        <Text>{children}</Text>
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default WarningModal
