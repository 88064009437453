import * as R from 'ramda'
import {
  CaseInputNameType,
  CaseNameArrayType,
  EconomicMetricsNamesType,
  OnelineNamesType,
  ResultsSubObjectType,
} from '.'
import createTableRowDataArray from './createTableRowDataArray'

const createTableRowDataArrayPretty = (
  makePretty: (
    x: number | string | undefined | null,
  ) => number | string | undefined,
  results: any,
  subObjectToReachInto: ResultsSubObjectType,
  dataNameInSubObject:
    | EconomicMetricsNamesType
    | CaseInputNameType
    | OnelineNamesType,
  caseNames: CaseNameArrayType,
) =>
  R.compose(R.map(makePretty))(
    createTableRowDataArray(
      results,
      subObjectToReachInto,
      dataNameInSubObject,
      caseNames,
    ),
  )

export default createTableRowDataArrayPretty
