import React, { Fragment, useState } from 'react'
import { Table } from 'evergreen-ui'
import RowTitle from './RowTitle'
import PlotLineGraph from './PlotLineGraph'

import logo from '../images/logo-footer.png'

import {
  numberFormatter,
  createPlotDataObjectArraySpecificCases,
  currencyFormatter,
  CASE_HEADERS,
  CASE_HEADERS_LABELS,
  MAX_CHARACTERS_PRICE_FORECAST_NAME,
  EconomicMetricsNamesType,
  CaseInputNameType,
  OnelineNamesType,
  ResultsSubObjectType,
  CaseNameArrayType,
  PRE_CALCULATION_WARNING_MESSAGE,
} from '../utils'

import '../styles/global.css'
import '../styles/print.css'
import { formatHEConomicsNumber } from '../utils/formatHEConomicsNumber'
import { truncateString } from '../utils/truncateString'
import PrintViewTableRow from './PrintViewTableRow'
import createTableRowDataArrayPretty from '../utils/createTableRowDataArrayPretty'
import { formatHEConomicsPayout } from '../utils/formatHEConomicsPayout'
import { formatHEConomicsRor } from '../utils/formatHEConomicsRor'
import filterToMajorPhase from '../utils/filterToMajorPhase'
import SectionHeader from './SectionHeader'
import WarningModal from './WarningModal'

const grabTableRowDataMakePretty = (
  rowMetadata: RowMetadataType,
  results: any,
  caseNames: CaseNameArrayType
) => {
  if (rowMetadata.dataName === 'ror') {
    return createTableRowDataArrayPretty(
      formatHEConomicsRor,
      results,
      rowMetadata.subObject,
      rowMetadata.dataName,
      caseNames
    )
  } else if (rowMetadata.dataName === 'payout') {
    return createTableRowDataArrayPretty(
      formatHEConomicsPayout,
      results,
      rowMetadata.subObject,
      rowMetadata.dataName,
      caseNames
    )
  } else {
    return createTableRowDataArrayPretty(
      formatHEConomicsNumber(
        rowMetadata.numberFormat === 'currency',
        rowMetadata.numDecimalDigits
      ),
      results,
      rowMetadata.subObject,
      rowMetadata.dataName,
      caseNames
    )
  }
}

export type PricingInputsType = {
  Gas: number[]
  Oil: number[]
  NGL?: number[]
  PriceName: string
}

const PLACEHOLDER_EMPTY_RESULTS = {
  results: {},
  projectInputs: { major: 'Oil' },
  pricingInputs: {},
}

const PrintView = () => {
  let localStateRaw, localState
  if (typeof window === 'undefined') {
    localState = PLACEHOLDER_EMPTY_RESULTS
  } else {
    localStateRaw = localStorage.getItem('localState')
    if (localStateRaw === null) {
      localState = PLACEHOLDER_EMPTY_RESULTS
    } else {
      localState = JSON.parse(localStateRaw)
    }
  }
  console.log('in PrintView.  Here is localState:  ', localState)

  // const [localState, setLocalState] = useState(
  //   typeof window !== `undefined`
  //     ? JSON.parse(localStorage.getItem('localState'))
  //     : { results: {}, projectInputs: { major: 'Oil' }, pricingInputs: {} }
  // )
  const { results, projectInputs, pricingInputs } = localState
  const { incrementalRisked, incrementalUnrisked } = results
  const [isResultsEmpty, setIsResultsEmpty] = useState(
    projectInputs?.projectName === undefined ? true : false
  )
  const [isCalcWarningOpen, setIsCalcWarningOpen] = useState(
    !isResultsEmpty && incrementalRisked?.economicMetrics?.pvr10 === undefined
      ? true
      : false
  )

  console.log(
    'PrintView:30, just grabbed localState.  Here is the results object:  ',
    results
  )

  const netProductionArray = (results) =>
    createPlotDataObjectArraySpecificCases(
      ['incrementalRisked'],
      ['Incremental Risked'],
      results,
      'boeNetDaily'
    )
  const opexTotalNetArray = (results) =>
    createPlotDataObjectArraySpecificCases(
      ['incrementalRisked'],
      ['Incremental Risked'],
      results,
      'opexOverheadTaxTotalNet'
    )
  const netIncomeCumArray = (results) =>
    createPlotDataObjectArraySpecificCases(
      ['incrementalRisked'],
      ['Incremental Risked'],
      results,
      'netIncomeCum'
    )

  const renderCashflowRow = (
    pvTitle,
    pvValue,
    yardstickTitle,
    riskedValue,
    unriskedValue
  ) => (
    <div
      className={
        pvTitle === 'PV 10%' ? 'cashflow-row yellow-background' : 'cashflow-row'
      }
    >
      <div
        className="w_20"
        style={{ paddingLeft: '10px', fontSize: '16px', fontWeight: '600' }}
      >
        {pvTitle}
      </div>
      <div className="right w_20">{pvValue}</div>
      <div
        className="right w_20"
        style={{ fontSize: '13.5px', fontWeight: '600' }}
      >
        {yardstickTitle}
      </div>
      <div className="right w_20">{riskedValue}</div>
      <div className="right w_20">{unriskedValue}</div>
    </div>
  )

  const NewLine = ({ children }) =>
    children.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ))

  const renderProjectInputs = (title, attribute) => (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: '50%',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '1rem',
          fontWeight: '700',
        }}
      >
        {title}
      </div>
      <div>{attribute}</div>
    </div>
  )

  // if (incrementalRisked?.economicMetrics?.pvr10 === undefined)
  //   return (
  //     <div className="print-container">
  //       <div className="calculation-warning">
  //         <Alert severity="warning">{PRE_CALCULATION_WARNING_MESSAGE}</Alert>
  //       </div>
  //     </div>
  //   )

  return (
    <>
      <div className="print-container">
        <div className="print-header">
          Expected Value Economics - Oil and Gas Wells
        </div>
        <div className="print-top">
          <div className="print-project-inputs">
            {renderProjectInputs('Project Name:', projectInputs?.projectName)}
            {renderProjectInputs('Well:', projectInputs?.well)}
            {renderProjectInputs('Field:', projectInputs?.field)}
            {renderProjectInputs('Formation:', projectInputs?.formation)}
            {renderProjectInputs('State:', projectInputs?.state)}
            {renderProjectInputs(
              'Working Int.:',
              typeof projectInputs?.workingInterestPercent == `undefined`
                ? ''
                : `${projectInputs?.workingInterestPercent} %`
            )}
            {renderProjectInputs(
              'Revenue Int.:',
              `${projectInputs?.netRevenueInterestPercent} %`
            )}
            {renderProjectInputs('Major Phase:', projectInputs?.major)}
            {renderProjectInputs(
              'Success P(s):',
              `${projectInputs?.successPs} %`
            )}
            {renderProjectInputs('Project Type:', projectInputs?.type)}
            <div className="print-description">
              <div style={{ padding: '0.2rem 0', fontWeight: '700' }}>
                Description:
              </div>
              <NewLine>{projectInputs?.description || ''}</NewLine>
            </div>
          </div>
          <div className="print-prices-cashflow-taxes">
            {pricesSection(pricingInputs, projectInputs)}
            <div className="print-view-cashflow">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '18px',
                  fontWeight: '700',
                }}
              >
                Incremental Net Cash Flow ($)
              </div>
              <div
                style={{ display: 'flex', fontSize: '16px', fontWeight: '600' }}
              >
                <div
                  style={{
                    width: '40%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  Risked PV Profile
                </div>
                <div className="right w_20">Yardsticks</div>
                <div className="right w_20">Risked</div>
                <div className="right w_20">Unrisked</div>
              </div>
              {renderCashflowRow(
                'PV 0%',
                currencyFormatter(incrementalRisked?.oneline?.netIncome),
                'PVR-0%',
                numberFormatter(incrementalRisked?.economicMetrics?.pvr0, 2),
                numberFormatter(incrementalUnrisked?.economicMetrics?.pvr0, 2)
              )}
              {renderCashflowRow(
                'PV 10%',
                currencyFormatter(incrementalRisked?.oneline?.presentValue10),
                'PVR-10%',
                numberFormatter(incrementalRisked?.economicMetrics?.pvr10, 2),
                numberFormatter(incrementalUnrisked?.economicMetrics?.pvr10, 2)
              )}
              {renderCashflowRow(
                'PV 15%',
                currencyFormatter(incrementalRisked?.oneline?.presentValue15),
                'PVR-15%',
                numberFormatter(incrementalRisked?.economicMetrics?.pvr15, 2),
                numberFormatter(incrementalUnrisked?.economicMetrics?.pvr15, 2)
              )}
              {renderCashflowRow(
                'PV 20%',
                currencyFormatter(incrementalRisked?.oneline?.presentValue20),
                'IRR %',
                incrementalRisked?.economicMetrics?.ror,
                incrementalUnrisked?.economicMetrics?.ror
              )}
              {renderCashflowRow(
                'PV 25%',
                currencyFormatter(incrementalRisked?.oneline?.presentValue25),
                'Net Inv. ($)',
                currencyFormatter(incrementalRisked?.oneline?.capitalNet),
                currencyFormatter(incrementalUnrisked?.oneline?.capitalNet)
              )}
              {renderCashflowRow(
                'PV 50%',
                currencyFormatter(incrementalRisked?.oneline?.presentValue50),
                'Net CF ($/mo)',
                currencyFormatter(
                  incrementalRisked?.economicMetrics?.initialNetOperatingIncome
                ),
                currencyFormatter(
                  incrementalUnrisked?.economicMetrics
                    ?.initialNetOperatingIncome
                )
              )}
              {renderCashflowRow(
                'PV 100%',
                currencyFormatter(incrementalRisked?.oneline?.presentValue100),
                'Payout (mos)',
                incrementalRisked?.economicMetrics?.payout < 0
                  ? 'No Payout'
                  : incrementalRisked?.economicMetrics?.payout < 1
                  ? '< 1'
                  : numberFormatter(
                      incrementalRisked?.economicMetrics?.payout,
                      0
                    ),
                incrementalUnrisked?.economicMetrics?.payout < 0
                  ? 'No Payout'
                  : incrementalUnrisked?.economicMetrics?.payout < 1
                  ? '< 1'
                  : numberFormatter(
                      incrementalUnrisked?.economicMetrics?.payout,
                      0
                    )
              )}
              {renderCashflowRow(
                'PV 200%',
                currencyFormatter(incrementalRisked?.oneline?.presentValue200),
                'Net MBOE',
                numberFormatter(incrementalRisked?.economicMetrics?.boeNetMboe),
                numberFormatter(
                  incrementalUnrisked?.economicMetrics?.boeNetMboe
                )
              )}
              {renderCashflowRow(
                'PV 300%',
                currencyFormatter(incrementalRisked?.oneline?.presentValue300),
                'Dev $/BOE',
                currencyFormatter(
                  incrementalRisked?.economicMetrics?.dollarsPerBOE,
                  2
                ),
                currencyFormatter(
                  incrementalUnrisked?.economicMetrics?.dollarsPerBOE,
                  2
                )
              )}
              {renderCashflowRow(
                'P10:P90',
                results?.ratioP10P90,
                'Dev $/BOEPD',
                currencyFormatter(
                  incrementalRisked?.economicMetrics?.dollarsPerBOEPD
                ),
                currencyFormatter(
                  incrementalUnrisked?.economicMetrics?.dollarsPerBOEPD
                )
              )}
            </div>
            <div className="print-taxes">
              <div
                className="print-taxes-section"
                style={{ textAlign: 'right' }}
              >
                <div>{`${projectInputs.state || 'Not Specified'}`}</div>
                <div>Tax Information</div>
              </div>
              <div className="print-taxes-section">
                <div className="print-taxes-item">
                  <div style={{ width: '50%', textAlign: 'right' }}>
                    Gas Sev. %:
                  </div>
                  <div>
                    {`${numberFormatter(
                      projectInputs?.gasSevTaxRatePercent,
                      2
                    )} %`}
                  </div>
                </div>
                <div className="print-taxes-item">
                  <div style={{ width: '50%', textAlign: 'right' }}>
                    Oil Sev. %:
                  </div>
                  <div>
                    {`${numberFormatter(
                      projectInputs?.oilSevTaxRatePercent,
                      2
                    )} %`}
                  </div>
                </div>
              </div>
              <div className="print-taxes-section">
                <div className="print-taxes-item">
                  <div style={{ width: '70%', textAlign: 'right' }}>
                    Gas Prod. $/MCF:
                  </div>
                  <div>
                    {currencyFormatter(projectInputs?.gasProdTaxRate, 2)}
                  </div>
                </div>
                <div className="print-taxes-item">
                  <div style={{ width: '70%', textAlign: 'right' }}>
                    Oil Prod. $/BBL:
                  </div>
                  <div>
                    {currencyFormatter(projectInputs?.oilProdTaxRate, 2)}
                  </div>
                </div>
              </div>
              <div className="print-taxes-section">
                <div className="print-taxes-item">
                  <div style={{ width: '50%', textAlign: 'right' }}>
                    Ad Val. %:
                  </div>
                  <div>
                    {`${numberFormatter(
                      projectInputs?.adValTaxRatePercent,
                      2
                    )} %`}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            minHeight: '150px',
          }}
        >
          <div className="graphs-container">
            <div className="graphs-section boepd">
              <PlotLineGraph
                plotDataArray={netProductionArray(results)}
                width="390"
                height="300"
                xAxisLabel="Year"
                yAxisLabel=""
                yAxisType="log"
                title="Production Rate"
                subTitle="Net Daily BOE"
                showLegend={false}
              />
            </div>
            <div className="graphs-section opex">
              <PlotLineGraph
                plotDataArray={opexTotalNetArray(results)}
                width="390"
                height="300"
                xAxisLabel="Year"
                yAxisLabel=""
                yAxisType="cartesian"
                title="Operating Cost"
                subTitle="Net $/month, OPEX + Tax + Overhead"
                showLegend={false}
              />
            </div>
            <div className="graphs-section net-income">
              <PlotLineGraph
                plotDataArray={netIncomeCumArray(results)}
                width="390"
                height="300"
                xAxisLabel="Year"
                yAxisLabel=""
                yAxisType="cartesian"
                title="Cumulative Net Income"
                subTitle="Net $ vs. Time"
                showLegend={false}
              />
            </div>
          </div>
        </div>
        <div className="print-table">
          <table border width={'100%'}>
            <Table.Head>
              <RowTitle title={` `} />
              {CASE_HEADERS_LABELS.map((item, index) => (
                <Table.TextHeaderCell
                  key={index}
                  style={{ textAlign: 'right' }}
                >
                  {item}
                </Table.TextHeaderCell>
              ))}
            </Table.Head>
            <tbody>
              <Table.Row height={25}>
                <RowTitle title="Case Weight Factors (%)" />
                {[0.3, 0.4, 0.3, 1].map((item, index) => (
                  <Table.TextCell key={index} className="case-item">
                    {Number(projectInputs.successPs)
                      ? `${numberFormatter(
                          projectInputs.successPs * item,
                          1
                        )} %`
                      : '-'}
                  </Table.TextCell>
                ))}
                <Table.TextCell className="case-item">
                  {Number(projectInputs.successPs)
                    ? `${numberFormatter(100 - projectInputs.successPs, 1)} %`
                    : '-'}
                </Table.TextCell>
                {[1, 1, 1].map((item, index) => (
                  <Table.TextCell key={index} className="case-item">
                    {Number(projectInputs.successPs)
                      ? `${numberFormatter(100 * item, 1)} %`
                      : '-'}
                  </Table.TextCell>
                ))}
              </Table.Row>
              {/* <Table.Row height={22}>A new table row</Table.Row> */}
              {/* <SectionHeader title="My cool SectionHeader" /> */}
              {filterToMajorPhase(
                projectInputs?.major,
                rowMetadataArray
              ).map((rowMetadata, index) =>
                rowMetadata.isSectionheader ? (
                  <SectionHeader title={rowMetadata.title} />
                ) : (
                  <PrintViewTableRow
                    key={index}
                    cssClassName={
                      rowMetadata.cssClassName ||
                      (index % 2 === 0 ? 'gray-row' : 'white-row')
                    }
                    rowTitle={rowMetadata.title}
                    dataValues={grabTableRowDataMakePretty(
                      rowMetadata,
                      results,
                      CASE_HEADERS
                    )}
                  />
                )
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="print-summary-table">
          <Table border>
              <TableHeader headers={summaryHeaders} />
              <Table.B
              </Table.Body>
          </Table>
      </div> */}
      </div>
      <WarningModal
        isOpen={isResultsEmpty}
        onClose={() => setIsResultsEmpty(false)}
      >
        <div>
          <p>
            Hmmm, the results are not available. This often happens when an old
            cached page is being presented by the web browser. Try this...
          </p>
          <p>
            'Hard refresh' this page by holding down control and shift while
            clicking the reload button.
          </p>
          <p>
            Then, go back to the HEConomics main page, click 'Calculate' and
            then click 'Print View'.
          </p>
          <p>Hopefully that will get you going...</p>
          <p>
            If all else fails, call your friendly Reserves Team crew - the app
            maintainers.
          </p>
        </div>
      </WarningModal>
      <WarningModal
        isOpen={isCalcWarningOpen}
        onClose={() => setIsCalcWarningOpen(false)}
      >
        {PRE_CALCULATION_WARNING_MESSAGE}
      </WarningModal>
    </>
  )
}

// const summaryHeaders = ['Year', 'Gross Gas (MCF)', 'Gross Oil (BO)', 'Net Sales Gas (MCF)', 'Net Sales Oil (BO)', 'Net Rev ($)', 'Net CF ($)', 'Cum Net CF ($)', 'Lift Cost $/BOE', 'Lift+Dev Cost $/BOE' ]

export type RowMetadataType = {
  title: string
  isSectionHeader?: true | false
  dataName?: EconomicMetricsNamesType | CaseInputNameType | OnelineNamesType
  subObject?: ResultsSubObjectType //'caseInput' | 'oneline' | 'economicMetrics'
  numberFormat?: 'currency'
  numDecimalDigits?: number
  cssClassName?: 'yellow-row'
  majorPhaseSpecific?: 'Oil' | 'Gas'
}

const rowMetadataArray: RowMetadataType[] = [
  // {
  //   title: 'Investment ',
  //   isSectionHeader: true,
  // },
  {
    title: 'Gross Investment ($)',
    dataName: 'capitalGross',
    subObject: 'oneline',
    numberFormat: 'currency',
  },
  {
    title: 'Net Investment ($)',
    dataName: 'capitalNet',
    subObject: 'oneline',
    numberFormat: 'currency',
    cssClassName: 'yellow-row',
  },
  {
    title: 'Gross Initial Oil Rate (BOPD)',
    dataName: 'initialDailyOilGross',
    subObject: 'economicMetrics',
  },
  {
    title: 'Gross Initial Gas Rate (MCFD)',
    dataName: 'initialDailyGasGross',
    subObject: 'economicMetrics',
  },
  {
    title: 'Gross Initial NGL Rate (BOPD)',
    dataName: 'initialDailyNglGross',
    subObject: 'economicMetrics',
  },
  {
    title: 'Net Initial BOE Rate (BOEPD)',
    dataName: 'initialDailyBoeNet',
    subObject: 'economicMetrics',
    cssClassName: 'yellow-row',
  },
  {
    title: 'Gross Final Gas Rate (MCFPD)',
    dataName: 'finalDailyGasGross',
    subObject: 'economicMetrics',
    majorPhaseSpecific: 'Gas',
  },
  {
    title: 'Gross Final Oil Rate (BOPD)',
    dataName: 'finalDailyOilGross',
    subObject: 'economicMetrics',
    majorPhaseSpecific: 'Oil',
  },
  {
    title: 'Initial Decline Rate (%/year)',
    dataName: 'annualEffectiveDeclineRatePercent',
    subObject: 'economicMetrics',
    numDecimalDigits: 1,
  },
  {
    title: 'Delay Start Date (Months)',
    dataName: 'delayStart',
    subObject: 'economicMetrics',
  },
  {
    title: 'Hyperbolic Exponent',
    dataName: 'hyperbolicExponent',
    subObject: 'caseInput',
    numDecimalDigits: 1,
  },
  {
    title: 'Terminal Decline Rate (%/year)',
    dataName: 'terminalAnnualEffectiveDeclineRatePercent',
    subObject: 'caseInput',
    numDecimalDigits: 1,
  },
  {
    title: 'Producing GOR (MCF/BO)',
    dataName: 'gasOilRatio',
    subObject: 'economicMetrics',
    numDecimalDigits: 1,
    majorPhaseSpecific: 'Oil',
  },
  {
    title: 'Condensate Yield (BO/MMCF)',
    dataName: 'condensateYield',
    subObject: 'economicMetrics',
    numDecimalDigits: 1,
    majorPhaseSpecific: 'Gas',
  },
  {
    title: 'NGL Yield (BO/MMCF)',
    dataName: 'nglYield',
    subObject: 'economicMetrics',
    numDecimalDigits: 1,
  },
  {
    title: 'Gross Oil Reserves (MBO)',
    dataName: 'oilGrossMbo',
    subObject: 'economicMetrics',
    numDecimalDigits: 2,
  },
  {
    title: 'Gross Gas Reserves (BCF)',
    dataName: 'gasGrossBcf',
    subObject: 'economicMetrics',
    numDecimalDigits: 2,
  },
  {
    title: 'Net Oil Equivalent (MBOE)',
    dataName: 'boeNetMboe',
    subObject: 'economicMetrics',
    cssClassName: 'yellow-row',
    numDecimalDigits: 2,
  },
  {
    title: 'Gross Variable Oil OPEX ($/BO)',
    dataName: 'opexVariableOilGross',
    subObject: 'economicMetrics',
    numberFormat: 'currency',
    numDecimalDigits: 2,
  },
  {
    title: 'Gross Variable Gas OPEX ($/MCF)',
    dataName: 'opexVariableGasGross',
    subObject: 'economicMetrics',
    numberFormat: 'currency',
    numDecimalDigits: 2,
  },
  {
    title: 'Gross Fixed Well Cost ($/mo)',
    dataName: 'opexPerWellGross',
    numberFormat: 'currency',
    subObject: 'economicMetrics',
  },
  {
    title: 'Gross Overhead ($/mo)',
    dataName: 'overheadGross',
    numberFormat: 'currency',
    subObject: 'economicMetrics',
  },
  {
    title: 'Gross Month 1 Total OPEX ($/mo)',
    dataName: 'initialOpexTotalGross',
    subObject: 'economicMetrics',
    numberFormat: 'currency',
  },
  {
    title: 'Net Month 1 OPEX+OH+Tax ($/mo)',
    dataName: 'initialOpexOverheadTaxTotalNet',
    numberFormat: 'currency',
    subObject: 'economicMetrics',
  },
  {
    title: 'Initial Net Cash Flow ($/mo)',
    dataName: 'initialNetOperatingIncome',
    subObject: 'economicMetrics',
    numberFormat: 'currency',
  },
  {
    title: 'Payout (Relative Months)',
    dataName: 'payout',
    subObject: 'economicMetrics',
  },
  {
    title: 'Economic Life (Years)',
    dataName: 'economicLifeYears',
    subObject: 'economicMetrics',
    numDecimalDigits: 1,
  },
  {
    title: 'Net PV-10% ($)',
    dataName: 'presentValue10',
    subObject: 'oneline',
    numberFormat: 'currency',
    cssClassName: 'yellow-row',
  },
  {
    title: 'Net PV-15% ($)',
    dataName: 'presentValue15',
    subObject: 'oneline',
    numberFormat: 'currency',
  },
  {
    title: 'PVR-10% ($/$)',
    dataName: 'pvr10',
    subObject: 'economicMetrics',
    cssClassName: 'yellow-row',
    numDecimalDigits: 2,
  },
  {
    title: 'PVR-15% ($/$)',
    dataName: 'pvr15',
    subObject: 'economicMetrics',
    numDecimalDigits: 2,
  },
  {
    title: 'IRR (%)',
    dataName: 'ror',
    subObject: 'economicMetrics',
  },
  {
    title: 'Dev. Cost ($/BOE)',
    dataName: 'dollarsPerBOE',
    subObject: 'economicMetrics',
    numberFormat: 'currency',
    numDecimalDigits: 2,
  },
]

const pricesSection = (prices: PricingInputsType, project) => (
  <div className="print-price-inputs">
    <div
      className="print-price-row margin-top-large print-price-header"
      style={{ fontSize: '18px' }}
    >
      Prices:&nbsp;
      <i>
        {truncateString(MAX_CHARACTERS_PRICE_FORECAST_NAME, prices?.PriceName)}
      </i>
    </div>
    <div className="print-price-row margin-top-large">
      <div className="w_30" style={{ width: '25%' }}></div>
      <div className="right">Month</div>
      <div className="right" style={{ width: '23%' }}>
        $/BBL
      </div>
      <div className="right" style={{ width: '23%' }}>
        $/MCF
      </div>
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%',
        }}
      >
        <img style={{ marginBottom: '0' }} src={logo} alt="" />
      </div>
      <div style={{ width: '75%' }}>
        {pricesSectionHeaders.map((item, index) => (
          <div key={index} className="print-price-row padding-small">
            <div className="right" style={{ width: '23%' }}>
              {item}
            </div>
            <div className="right" style={{ width: '23%' }}>
              {formatHEConomicsNumber(false, 2, prices?.Oil?.[item])}
            </div>
            <div className="right w_30">
              {formatHEConomicsNumber(false, 2, prices?.Gas?.[item])}
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className="print-price-row padding-small">
      <div className="right w_35" style={{ paddingRight: '2px' }}>
        Price Diff ($)
      </div>
      <div className="right w_30" style={{ paddingRight: '2px' }}>
        {formatHEConomicsNumber(false, 2, +project.priceDiffOil)}
      </div>
      <div className="right w_30" style={{ paddingRight: '15px' }}>
        {formatHEConomicsNumber(false, 2, +project.priceDiffGas)}
      </div>
    </div>
    <div className="print-price-row padding-small">
      <div
        className="right w_35"
        style={{ textAlign: 'right', paddingRight: '2px' }}
      >
        NGL Price (%)
      </div>
      <div
        className="right w_30"
        style={{ paddingRight: '2px' }}
      >{`${formatHEConomicsNumber(false, 1, +project.nglPricePercent)} %`}</div>
      <div className="right w_30"></div>
    </div>
    <div className="print-price-row padding-small">
      <div className="right w_35" style={{ paddingRight: '2px' }}>
        Shrink (%)
      </div>
      <div className="right w_30"></div>
      <div className="right w_30">{`${formatHEConomicsNumber(
        false,
        1,
        +project.shrinkPercent
      )} %`}</div>
    </div>
  </div>
)

const pricesSectionHeaders = [1, 12, 24, 36]

export default PrintView
