import * as React from 'react'
import RowTitle from './RowTitle'

import {Table} from 'evergreen-ui'

type PrintViewTableRowType = {
  cssClassName: 'white-row' | 'gray-row' | 'yellow-row'
  rowTitle: string
  dataValues: (number | string | undefined)[]
}

const PrintViewTableRow = ({
  cssClassName,
  rowTitle,
  dataValues,
}: PrintViewTableRowType) => (
  <Table.Row height={22} className={cssClassName}>
    <RowTitle title={rowTitle} />
    {dataValues.map((dataValue, index) => (
      <Table.TextCell
        key={index}
        style={{textAlign: 'right', paddingRight: '25px'}}
      >
        {dataValue}
      </Table.TextCell>
    ))}
  </Table.Row>
)

export default PrintViewTableRow
