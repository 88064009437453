import * as R from 'ramda'
import {
  CaseInputNameType,
  CaseNameArrayType,
  EconomicMetricsNamesType,
  OnelineNamesType,
  ResultsSubObjectType,
} from '.'

const createTableRowDataArray = (
  results: any,
  subObjectToReachInto: ResultsSubObjectType,
  dataNameInSubObject:
    | EconomicMetricsNamesType
    | CaseInputNameType
    | OnelineNamesType,
  caseNames: CaseNameArrayType,
) =>
  R.map(
    caseName =>
      results?.[caseName]?.[subObjectToReachInto]?.[dataNameInSubObject],
  )(caseNames)

export default createTableRowDataArray
