import React from 'react';
import { Provider } from 'react-redux';

import SEO from '../components/seo';
import store from '../store';
import PrintView from '../components/PrintView';

const Print = () => (
  <Provider store={store}>
    <SEO title="Print View" />
    <PrintView />
  </Provider>
);

export default Print;
