import * as R from 'ramda'
import {RowMetadataType} from '../components/PrintView'

const isMajorSpecificOrMajorUndefined = R.curry(
  (majorPhase: 'Oil' | 'Gas', dataObject: RowMetadataType) =>
    dataObject.majorPhaseSpecific === majorPhase ||
    dataObject.majorPhaseSpecific === undefined,
)

/**
 * I use this function in the PrintView component to define which rows of
 * output to print, given a major phase of oil or gas.
 *
 * Some output is major phase dependent.  For example, Condensate Yield
 * should only appear on gas well output.
 *
 * Some rows need to appear all the time.  For example, Gross Investment
 * should appear on either gas or oil well output.
 *
 * An array of RowMetadataType objects are expected.  I return a filtered
 * array.  The filter...
 *   Includes objects where the majorPhaseSpecific value is undefined.
 *   Include objects where the majorPhaseSpecific is equal to the provided
 *   majorPhase
 *
 * @param majorPhase 'Oil' or 'Gas'
 * @param rowMetaDataArray RowMetadataType array
 * @returns rowMetatDataArray, filtered to items where the majorPhaseSpecific
 *  value is either undefined or equal to the provided majorPhase value
 */
const filterToMajorPhase = (
  majorPhase: 'Oil' | 'Gas',
  rowMetaDataArray: RowMetadataType[],
) => {
  if (majorPhase !== 'Oil' && majorPhase !== 'Gas')
    throw new Error(
      'Major phase not recognized.  The value must be Oil or Gas.  However, the following value was provided:  ' +
        majorPhase,
    )

  return R.filter(isMajorSpecificOrMajorUndefined(majorPhase), rowMetaDataArray)
}

export default filterToMajorPhase
