import { formatHEConomics } from './formatHEConomics'
import { formatNumberPretty } from './formatNumberPretty'

/**
 * cashflowjs returns a rate of return (ROR) value of ">300%"
 * or a number less than 300.
 *
 * This function returns ">300%" or the ror number.
 *
 * I pass this function to createTableRowDataArrayPretty, when
 * handling payout values.
 *
 * @param ror
 * @returns ror | ">300%"
 * @author Keith Elliott, 10/1/2021
 */
export const formatHEConomicsRor = (ror: number | string) => {
  return formatHEConomics((ror) => {
    if (typeof ror === 'number')
      if (isNaN(ror)) return '-'
      else return formatNumberPretty(false, 0, ror)

    if (ror === '>300%') return ror

    throw new Error(
      'Invalid number provided to formatHEConomicsRor.  A number or ">300%" is expected, ' +
        'however the following value, of type ' +
        typeof ror +
        ', was provided:  ' +
        ror
    )
  }, ror)
}
