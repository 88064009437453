import {formatHEConomics} from './formatHEConomics'

/**
 * The convention for defining "no payout" in the
 * cashflowjs economicMetrics results is to flag
 * no payout projects with a payout value of -1.
 *
 * But, -1 is not suitable for public consumption.
 *
 * Therefore, this function will swap negative payout values
 * for the string "No Payout".  Otherwise, the value is
 * returned unchanged.
 *
 * I pass this function to createTableRowDataArrayPretty, when
 * handling payout values.
 *
 * @param payout
 * @returns payout | "No Payout"
 * @author Keith Elliott, 10/1/2021
 */
export const formatHEConomicsPayout = (payout: number | string) => {
  return formatHEConomics(payout => {
    if (typeof payout === 'number')
      if (isNaN(payout)) return '-'
      else if (payout === -1) return 'No Payout'
      else return payout

    throw new Error(
      'Invalid number provided to formatHEConomicsPayout.  A number or empty string is expected, ' +
        'however the following value, of type ' +
        typeof payout +
        ', was provided:  ' +
        payout,
    )
  }, payout)
}
